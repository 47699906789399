import React, { useEffect, useState } from 'react'
import { lowerCase } from 'lodash'

import CROSS from './../pictos/cross.svg'

import './../pages/scss/Fiche.scss'

const FicheLecon = ({ close, eleves, datas, createLecon }) => {


    const [opacity, setOpacity] = useState(0)
    const [listeEleves, setListeEleves] = useState(eleves)
    const [filtreNom, setFiltreNom] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    useEffect(() => {
        let tabEleves = []
        if (filtreNom.length === 0 || filtreNom === "") {
            tabEleves = [...eleves]
        } else {
            eleves.map(eleve => {
                if (lowerCase(eleve.nom).includes(lowerCase(filtreNom)) || lowerCase(eleve.prenom).includes(lowerCase(filtreNom)) || lowerCase(`${eleve.prenom} ${eleve.nom}`).includes(lowerCase(filtreNom)) || lowerCase(`${eleve.nom} ${eleve.prenom}`).includes(lowerCase(filtreNom))) {
                    tabEleves.push(eleve)
                }
            })
        }
        setListeEleves([...tabEleves])
    }, [eleves, filtreNom])


    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    function valider(idEleve) {
        createLecon({ ...datas, idEleve })
        setTimeout(() => {
            fermer()
        }, 500)
    }


    return (
        <div className='flottant listeEleves' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1 className='titreListe'>A qui voulez-vous attribuer ce créneau?</h1>
            <input
                type='text'
                placeholder='Rechercher par nom et/ou prénom'
                className='rechercher'
                onChange={e => setFiltreNom(e.target.value)}
            />
            <div className='liste'>
                {listeEleves.length > 0 ?
                    listeEleves.map(e =>
                        <div
                            key={e.id}
                            className='eleve'
                            onClick={() => valider(e.id)}
                        >
                            {e.prenom} {e.nom}
                        </div>
                    ) :
                    <p className='aucunEleve'>Aucun élève ne correspond à cette liste...</p>
                }
            </div>
        </div>
    )
}


export default FicheLecon