import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CROSS from './../pictos/cross.svg'

const CreateAdmin = ({ close, create }) => {

    const [opacity, setOpacity] = useState(0)
    const [prenom, setPrenom] = useState("")
    const [nom, setNom] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    function _create() {
        create(prenom, nom, email)
        fermer()
    }

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    return (
        <div className='flottant ajoutAdmin' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1>Ajout d'un nouvel administrateur</h1>
            <h2>Attention, un administrateur a accès à tout<br />ne prenez pas cet accès à la légère</h2>
            <div className='contenu'>
                <input type="text" value={prenom} onChange={e => setPrenom(e.target.value)} placeholder="Prénom" />
                <input type="text" value={nom} onChange={e => setNom(e.target.value)} placeholder="Nom"/>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="email"/>
                <button onClick={() => _create()}>Créer</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        create: (prenom, nom, email) => dispatch({ type: `CREATE_ADMIN`, prenom, nom, email }),
    }
}


export default connect(null, mapDispatchToProps)(CreateAdmin)