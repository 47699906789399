const duree = (24 * 60 * 60) - 1

export default function BuildCalendar(value, lecons, examen) {

    const startDay = value.clone().startOf("month").startOf("isoWeek")
    const endDay = value.clone().endOf("month").endOf("isoWeek")
    const day = startDay.clone().subtract(1, "day")
    const calendar = []
    while (day.isBefore(endDay, "day")) {
        calendar.push(
            Array(7)
                .fill(0)
                .map(() => day.add(1, "day").clone())
        )
    }

    calendar.map(ligne => {
        ligne.map(jour => {
            //On regarde si il y a dans ce jour une leçon qui correspond (sinon tableau vide):
            jour.examen = false
            jour.lecons = []
            lecons.map(lecon=>{
                if(lecon.debut > jour.unix() && lecon.fin < jour.unix() + duree){
                    jour.lecons.push(lecon)
                }
            })
            if(examen.date > jour.unix() && examen.date < jour.unix() + duree){
                jour.examen = examen
            }
        })
    })
    return calendar
}