import React, { useEffect } from "react"

const MultipleChoice = ({ datas, current, setNewValue }) => {

    return (
        <div className="multipleChoice">
            {datas.map((data,i)=>
                <button key={data.id} className={i !== current ? "creux" : ""} onClick={()=>setNewValue(data.id)}>{data.nom}</button>
                )}
        </div>
    )
}

export default MultipleChoice