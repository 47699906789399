import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CROSS from './../pictos/cross.svg'

const HeuresMax = ({ agenceId, close, heuresInit, setLeconsMax, semainesInit }) => {

    const [heures, setHeures] = useState(heuresInit)
    const [semaines, setSemaines] = useState(semainesInit)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    function _set() {
        if (isNaN(heures) || isNaN(semaines)) {
            alert("Vous devez entrer des nombres pour les deux champs")
            return
        }
        setLeconsMax(parseInt(agenceId), parseInt(heures), parseInt(semaines))
        fermer()
    }

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    return (
        <div className='flottant ajoutHeures' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1 style={{fontSize: "0.8em"}}>Nombre de leçons maximum qu'un élève peut réserver sur une semaine?</h1>
            <div className='contenu'>
                <input type="number" value={heures} onChange={e => setHeures(e.target.value)} />
            </div>
            <h1 style={{fontSize: "0.8em", marginTop:'20px'}}>Nombre de semaines durant lesquelles les élèves peuvent réserver des leçons?</h1>
            <div className='contenu'>
                <input type="number" value={semaines} onChange={e => setSemaines(parseInt(e.target.value) || false)} />
            </div>
            <button onClick={() => _set() } style={{marginTop: '20px'}}>Valider</button>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setLeconsMax: (agenceId, lecons, semaines) => dispatch({ type: `SET_MAX_LESSONS`, agenceId, lecons, semaines }),
    }
}


export default connect(null, mapDispatchToProps)(HeuresMax)