import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import Loader from '../components/Loader'
import LineMoniteur from './../components/LineMoniteur'
import FicheMoniteur from '../components/FicheMoniteur'
import FicheIndispos from '../components/FicheIndispos'

import './scss/Eleve.scss'

const Moniteurs = ({ moniteurs, agences, getMoniteurs, getIndispos, indispos, fermerIndispos, setIndispos, delIndispo }) => {

    const [monits, setMonits] = useState(false)
    const [newMoniteur, setNewMoniteur] = useState(false)
    const [ficheMoniteur, setFicheMoniteur] = useState(false)

    useEffect(() => {
        getMoniteurs()
    }, [])

    useEffect(() => {
        setMonits(orderBy(moniteurs, ['agence_id'], ["asc"]))
    }, [moniteurs])

    function ajouter() {
        setNewMoniteur(true)
    }

    if (!monits || !agences) {
        return (<Loader />)
    } else {
        return (
            <div className='moniteurs'>
                <button onClick={() => ajouter()}>Ajouter</button>
                {newMoniteur && <FicheMoniteur close={() => setFicheMoniteur(false)} moniteur={false} agences={agences} exist={false} />}
                {ficheMoniteur && <FicheMoniteur close={() => setFicheMoniteur(false)} moniteur={ficheMoniteur} agences={agences} exist={true} />}
                {indispos &&
                    <FicheIndispos
                        fermer={() => fermerIndispos()}
                        moniteur={monits[monits.findIndex(m => m.id === indispos.moniteur)]}
                        indispos={indispos}
                        setIndispos={setIndispos}
                        delIndispo={delIndispo}
                    />
                }
                <table>
                    <tbody>
                        {monits.map(moniteur =>
                            <LineMoniteur
                                key={moniteur.id}
                                moniteur={moniteur}
                                agences={agences}
                                onClick={() => setFicheMoniteur(moniteur)}
                                onConges={() => getIndispos(moniteur.id)}
                            />
                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

const mapToProps = state => {
    return {
        moniteurs: state.moniteurs || false,
        agences: state.agences || false,
        indispos: state.indispos || false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMoniteurs: () => dispatch({ type: `GET_MONITEURS` }),
        getIndispos: (moniteur_id) => dispatch({ type: `GET_INDISPOS`, moniteur_id }),
        fermerIndispos: () => dispatch({ type: `GET_INDISPOS_ASYNC`, indispos: false }),
        setIndispos: (dates, moniteur_id) => dispatch({ type: `SET_INDISPOS`, dates, moniteur_id }),
        delIndispo: (id, moniteur_id) => dispatch({ type: `DEL_INDISPO`, id, moniteur_id })
    }
}


export default connect(mapToProps, mapDispatchToProps)(Moniteurs)