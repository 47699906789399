import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'

import ConfirmAnnulation from './../components/ConfirmAnnulation'


import FicheEleve from '../components/FicheEleve'
import AjoutHeures from '../components/AjoutHeures'
import DateExam from '../components/DateExam'
import DateCode from '../components/DateCode'
import Calendrier from '../components/calendrier/Calendrier'
import FicheSuppression from '../components/ConfirmSuppresison'

import { upperFirst, toUpper } from 'lodash'
import { TimestampToDate, Hours, Jour } from '../functions/utils'

import './scss/Eleve.scss'



const Eleve = ({ eleve, getEleve, razEleve, agences, calendrierEleve, getCalendrierEleve, delLecon, setAbsent, setPresent, getExamEleve, examsEleve, annuleExam, updateCommentaire, radie, deleteStudent }) => {

    const params = useParams()
    const navigate = useNavigate()

    const [addHours, setAddHours] = useState(false)
    const [dateExams, setDateExams] = useState(false)
    const [dateCode, setDateCode] = useState(false)
    const [ficheEleve, setFicheEleve] = useState(false)
    const [now, setNow] = useState(new Date())
    const [commentaire, setCommentaire] = useState("")
    const [showDelete, setShowDelete] = useState(false)
    const [datasDelLecon, setDatasDelLecon] = useState(false)
    const [datasAbsent, setDatasAbsent] = useState(false)

    useEffect(() => {
        setCommentaire(eleve.commentaire)
    }, [eleve])

    function recComment() {
        updateCommentaire(commentaire, parseInt(params.eleveId))
    }

    useEffect(() => {
        getEleve(params.eleveId)
        getExamEleve(params.eleveId)
        getCalendrierEleve(params.eleveId)
        const timer = setInterval(() => {
            getCalendrierEleve(params.eleveId)
            getExamEleve(params.eleveId)
        }, 2500)

        //Au démontage: il faut initialiser le state eleve à false
        return () => {
            razEleve()
            clearInterval(timer)
        }
    }, [])

    function _radie() {
        radie(parseInt(params.eleveId))
        setTimeout(() => {
            getEleve(params.eleveId)
        }, 2500)
    }

    const _deleteStudent = () => {
        deleteStudent(params.eleveId, eleve.email)
        setTimeout(() => {
            return navigate("/eleves")
        }, 500)
    }

    const _setPresent=(id)=>{
        if(window.confirm("Confirmez-vous vouloir annuler l'absence de cet élève?")){
            setPresent(id)
        }
        return
    }

    if (!eleve) {
        return (<Loader />)
    } else {
        return (
            <div className='eleve' >
                {showDelete &&
                    <FicheSuppression
                        oui={() => _deleteStudent()}
                        non={() => setShowDelete(false)}
                        fermer={() => setShowDelete(false)}
                    />
                }
                {datasDelLecon &&
                    <ConfirmAnnulation
                        oui={() => {
                            delLecon(datasDelLecon.debut, datasDelLecon.fin, datasDelLecon.moniteurId, datasDelLecon.eleveId, 1, datasDelLecon.BA, datasDelLecon.agenceId)
                            setDatasDelLecon(false)
                        }}
                        non={() => {
                            delLecon(datasDelLecon.debut, datasDelLecon.fin, datasDelLecon.moniteurId, datasDelLecon.eleveId, 0, datasDelLecon.BA, datasDelLecon.agenceId)
                            setDatasDelLecon(false)

                        }}
                        fermer={() => {
                            setDatasDelLecon(false)
                        }} />
                }
                {datasAbsent &&
                    <ConfirmAnnulation
                        oui={() => {
                            setAbsent(datasAbsent.leconId, datasAbsent.debut, 1)
                            setDatasAbsent(false)
                        }}
                        non={() => {
                            setAbsent(datasAbsent.leconId, datasAbsent.debut, 0)
                            setDatasAbsent(false)

                        }}
                        fermer={() => {
                            setDatasAbsent(false)
                        }} />
                }
                <div className='d'>
                    {addHours && <AjoutHeures close={() => setAddHours(false)} eleveId={params.eleveId} />}
                    {dateExams && <DateExam close={() => setDateExams(false)} eleveId={params.eleveId} />}
                    {dateCode && <DateCode close={() => setDateCode(false)} eleveId={params.eleveId} />}
                    {ficheEleve && <FicheEleve close={() => setFicheEleve(false)} eleveId={params.eleveId} eleve={eleve} />}
                    <div className='ligne1 flex2'>
                        <div>
                            <h1>{upperFirst(eleve.prenom)} {toUpper(eleve.nom)}</h1>
                            {eleve.isInscrit === 0 && <h1 className='radie'>N'est plus actif</h1>}
                            <h2 className="vert strong">Agence {agences && agences[agences.findIndex(a => a.id === eleve.agence_id)].nom}</h2>
                            <h2>inscrit depuis le {TimestampToDate(eleve.date_inscription)}</h2>
                            <div className='adresse'>
                                <p>{eleve.numero_rue} {eleve.rue}</p>
                                <p>{eleve.code_postal} {eleve.ville}</p>
                                <p>{eleve.telephone}</p>
                                <p>{eleve.email}</p>
                                <p>Né le <em>{TimestampToDate(new Date(eleve.date_naissance).getTime() / 1000)}</em></p>
                                <p><em>Naissance: {eleve.ville_naissance} | {eleve.pays_naissance}</em></p>
                                <p className='type_financement'>{eleve.type_financement === 0 ? "Financement personnel" : eleve.type_financement === 1 ? "Permis à 1€" : eleve.type_financement === 2 ? "Financeurs sociaux" : eleve.type_financement === 3 ? "CPF" : "CFA"}</p>
                                <p style={{ marginTop: "10px" }}><strong>NEPH: {eleve.neph}</strong></p>
                                <p className='obtentionCode'>
                                    {eleve.obtention_code !== null ?
                                        <strong>Code obtenu le {Jour(eleve.obtention_code)}</strong> :
                                        <em>{upperFirst(eleve.prenom)} {upperFirst(eleve.nom)}<br />n'a pas encore obtenu son code</em>
                                    }
                                    <button onClick={() => setDateCode(true)}>Date code</button>
                                </p>
                            </div>
                        </div>
                        <div className='buttons'>
                            <button onClick={() => setFicheEleve(true)}>Modifier</button>
                            <button className='saumon' onClick={() => _radie()}>{eleve.isInscrit ? "Archiver" : "Rétablir"}</button>
                        </div>
                    </div>
                    <div className='ligne2 flex2'>
                        <div className='stats'>
                            <p className='type_permis'>PERMIS B / {eleve.permis_bm === 1 ? "BM" : "BA"}</p>
                            {eleve.type_conduite !== 0 && <p className='type_conduite'>{eleve.type_conduite === 1 ? "Conduite supervisée" : eleve.type_conduite === 2 ? "Conduite accompagnée" : "Perfectionnement"}</p>}
                            <p className='paye'>Heures payées <strong>{eleve.credit_heures > 0 ? `${eleve.credit_heures}H` : "-"}</strong></p>
                            <p className='effectue'>Heures effectuées <strong>{eleve.stats.ok > 0 ? `${eleve.stats.ok}H` : "-"}</strong></p>
                            <p className='effectue'>Heures restantes <strong>{eleve.stats.restant > 0 ? `${eleve.stats.restant}H` : "-"}</strong></p>
                            <p className='absent'>Heures d'absence <strong>{eleve.stats.absent > 0 ? `${eleve.stats.absent}H` : "-"}</strong></p>
                            <div className='buttons'>
                                <button onClick={() => setAddHours(true)}>Rajouter des heures</button>
                                <button className='creux ' onClick={() => setDateExams(true)}>Planifier un examen</button>
                            </div>
                        </div>
                        <div>
                            <Calendrier eleve={eleve} />
                        </div>
                    </div>
                    <div className='text'>
                        <h1>Commentaire</h1>
                        <button onClick={() => recComment()} className="saumon" style={{ opacity: commentaire !== eleve.commentaire ? 1 : 0 }}>Enregistrer</button>
                        <textarea value={commentaire} onChange={e => setCommentaire(e.target.value)} />
                    </div>
                    <div className='supprimer' onClick={() => setShowDelete(true)}>
                        Supprimer définitivement
                    </div>
                </div>
                <div className='c'>
                    {examsEleve.length > 0 &&
                        <div className='examens'>
                            <h2>Examen{examsEleve.length > 1 && 's'}:</h2>
                            <ul className='listeExams'>
                                {examsEleve.map(exam =>
                                    <li key={exam.id} className={exam.date < Math.floor(now.getTime() / 1000) ? "past" : ""}>
                                        Le {Jour(exam.date)} à <strong>{Hours(exam.date)}</strong>
                                        {exam.commentaire && exam.commentaire.length > 0 && <span><br />Nota: {exam.commentaire}</span>}
                                        <button className='saumon creux' style={{ display: 'inline-block' }} onClick={() => annuleExam(exam.id)}>Annuler</button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                    <h2>{calendrierEleve.length} leçon{calendrierEleve.length > 1 && 's'}</h2>
                    <div className='listeLessons'>
                        {calendrierEleve.map(lesson => {
                            return (
                                <div key={lesson.id} className={lesson.isFutur ? "futur" : lesson.status === 1 ? "past" : "absent"}>
                                    <p>
                                        <strong>{Hours(lesson.debut)}</strong>
                                        à <strong>{Hours(lesson.fin)}</strong>
                                        le {Jour(lesson.debut)} - {lesson.prenom} {lesson.nom} </p>
                                    <div className='flex2'>
                                        <button
                                            className={`${lesson.status === 1 ? "creux" : ""} saumon`}
                                            style={{ marginRight: "5px" }}
                                            onClick={() => {
                                                if (lesson.status === 1) {
                                                    if (lesson.debut - now.getTime() / 1000 < 48 * 3600) {
                                                        setDatasAbsent({
                                                            leconId: lesson.id,
                                                            debut: lesson.debut
                                                        })
                                                    } else {
                                                        setAbsent(lesson.id, lesson.debut, 0)
                                                    }
                                                } else {
                                                    _setPresent(lesson.id)
                                                }
                                            }}
                                        >
                                            {lesson.status === 1 ? `Absent` : `Remettre`}
                                        </button>
                                        <button
                                            className='creux'
                                            onClick={() => {
                                                if (lesson.debut - now.getTime() / 1000 < 48 * 3600 && lesson.status === 1) {
                                                    setDatasDelLecon({
                                                        debut: lesson.debut,
                                                        fin: lesson.fin,
                                                        moniteurId: lesson.moniteurId,
                                                        eleveId: parseInt(params.eleveId),
                                                        BA: eleve.permis_bm !== 1,
                                                        agenceId: parseInt(eleve.agence_id)
                                                    })
                                                } else {
                                                    delLecon(lesson.debut, lesson.fin, lesson.moniteurId, parseInt(params.eleveId), 0, eleve.permis_bm !== 1, parseInt(eleve.agence_id))
                                                }
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div >
        )
    }
}

const mapToProps = state => {
    return {
        eleve: state.eleve || false,
        agences: state.agences || false,
        calendrierEleve: state.calendrierEleve || [],
        examsEleve: state.examsEleve || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCalendrierEleve: (id) => dispatch({ type: `GET_CALENDRIER_ELEVE`, id }),
        getEleve: (id) => dispatch({ type: `GET_ELEVE`, id }),
        razEleve: () => dispatch({ type: `RAZ_ELEVE` }),
        delLecon: (hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence) => dispatch({ type: `DEL_LECON`, hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence }),
        setAbsent: (id, debut, avertir) => dispatch({ type: `SET_ABSENT`, id, debut, avertir }),
        setPresent: (id) => dispatch({ type: `SET_PRESENT`, id }),
        getExamEleve: (id) => dispatch({ type: `GET_EXAMENS`, id }),
        annuleExam: (id) => dispatch({ type: `ANNULE_EXAM`, id }),
        updateCommentaire: (commentaire, id) => dispatch({ type: `SET_COMMENTAIRE`, commentaire, id }),
        radie: (id) => dispatch({ type: `RADIE`, id }),
        deleteStudent: (id, email) => dispatch({ type: `SUPPRIME_DEFINITIVEMENT`, id, email })
    }
}


export default connect(mapToProps, mapDispatchToProps)(Eleve)