import React, { useEffect, useState } from 'react'

import { TimestampToDayMonthYearHoursMinutes } from '../functions/utils'
import CROSS from './../pictos/cross.svg'

import './../pages/scss/Fiche.scss'


const FicheIndispos = ({ indispos, fermer, setIndispos, delIndispo , moniteur}) => {

    const [debut, setDebut] = useState('')
    const [fin, setFin] = useState('')

    function setIndispo() {
        const dd = new Date(debut)
        const df = new Date(fin)
        const ddTs = dd.getTime()
        const dfTs = df.getTime()
        if (isNaN(ddTs) || isNaN(dfTs)) {
            alert("Au moins un des 2 date n'est pas valide")
        } else if (dfTs <= ddTs) {
            alert("La date de fin ne peut pas être avant la date de début...")
        } else {
            setIndispos({ dates: { debut: ddTs, fin: dfTs } }, indispos.moniteur)
        }
    }

    return (
        <div className='flottant fiche-indispos'>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <div className='set-indispo'>
                <p>Créer une nouvelle indisponibilité pour <br/>
                <strong>{moniteur.prenom} {moniteur.nom}</strong>
                </p>
                <h2>
                    Début<input type='datetime-local'
                        onChange={e => setDebut(new Date(e.target.value))}
                    />
                </h2>
                <h2>
                    Fin<input type='datetime-local'
                        onChange={e => setFin(new Date(e.target.value))}
                    />
                </h2>
                <button onClick={() => setIndispo()}>Valider</button>
            </div>
            <div className='scroll' style={{maxHeight: `${window.innerHeight * 0.5}px`, paddingBottom: '100px'}}>
            {indispos.indispos.length > 0 &&
                indispos.indispos.map(indispo =>
                    <div className='indispo' key={indispo.id}>
                        Indisponible pour des leçons du <br /><span>{TimestampToDayMonthYearHoursMinutes(indispo.debut)}</span> <br />au <br /><span>{TimestampToDayMonthYearHoursMinutes(indispo.fin)}</span>
                        <button className='saumon' onClick={()=>delIndispo(indispo.id, indispos.moniteur)}>Supprimer</button>
                    </div>
                )}
                </div>
        </div>
    )
}

export default FicheIndispos