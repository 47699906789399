function isSelected(day, value){
    return value.isSame(day, "day")
}

function isToday(day){
   return day.isSame(new Date(), "day")
}

function isBeforeMonth(day, value){
   return day.isBefore(value, "month")
}

function isAfterMonth(day, value){
   return day.isAfter(value, "month")
}

export default function dayStyles(day, value){
   if(isToday(day)) return "today"
   else if(isBeforeMonth(day, value)) return "beforeMonth"
   else if(isAfterMonth(day, value)) return "afterMonth"
   else return ""
}