
import axios from 'axios'

let refreshToken = ""
const dev = process.env.NODE_ENV === "development"

const client = axios.create({
baseURL: dev ? `http://localhost:6459/admin/` :` https://serveur.milkyway-autoecole.com/admin/`
})

client.interceptors.response.use(resp => {
    if(resp.data.errMess){
        alert(resp.data.errMess)
    }
    return resp
}, async err => {
    const originalRequest = err.config
    if (err.response === undefined) {
        return client(originalRequest)
    }else if (err.config.url === '/login' && err.response.status === 401) {
        window.location.href = ('/connection-fail')
    } else {
        //Quand il y a une erreur on a cette seconde fonction asynchrone
        // ici elle va essayer de générer un nouveau token
        if (err.config.url !== '/refreshtoken' && err.response.status === 401) {
            if (refreshToken && refreshToken !== '') {
                client.defaults.headers.common['authorization'] = `Bearer ${refreshToken}`
                await client.get("/refreshtoken")
                    .then(({ data }) => {
                        client.defaults.headers.common['authorization'] = `Bearer ${data.accessToken}`
                        originalRequest.headers['authorization'] = `Bearer ${data.accessToken}`
                    })
                    .catch(err => {
                        alert("Une erreur est survenue, merci de vous reconnecter")
                        window.location.reload()
                        refreshToken = null
                    })
                
            } else {
                alert("Une erreur inatendue est survenue, merci de vous reconnecter")
            }
            return client(originalRequest)
        } else {
            alert("Votre session a expiré, merci de voux reconnecter")
            window.location.reload()
        }
    }
})


export const apiCall = function (method, route, body = null, token = null) {

    const onSuccess = function (response) {
        if (route === "/login") { //Si route est /login c'est le premier appel, il faut init les tokens
            client.defaults.headers.common['authorization'] = `Bearer ${response.data.accessToken}`
            refreshToken = response.data.refreshToken
        }
        return response.data
    }

    const onError = function (error) {
        console.error('Request Failed:', error.config)

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    }

    return client({
        method,
        url: route,
        data: body
    })
        .then(onSuccess)
        .catch(onError);
}

export const logOut = () => {
    refreshToken = "BAD"
    client.defaults.headers.common['authorization'] = `BAD`
    return ({ logout: true })
}

