import React, { useEffect, useState } from 'react'

import CROSS from './../pictos/cross.svg'

import './../pages/scss/Fiche.scss'


const FicheSuppression = ({ oui, non, fermer }) => {

    return (
        <div className='flottant fiche-annulation'>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <div className='set-indispo'>
                <p><strong style={{color: `#e41e18`}}>ATTENTION</strong></p>
                <p>Vous êtes sur le point de supprimer définitivement un élève</p>
                <p>Une suppression est <strong>irréversible</strong>, toutes les données de l'élève seront <strong>effacées</strong><br/>(leçons, dates d'examens etc...)</p>
                <div className='buttons'>
                    <button onClick={() => oui()}>Supprimer</button>
                    <button className='saumon' onClick={() => non()}>Annuler</button>
                </div>
            </div>
        </div>
    )
}

export default FicheSuppression