import React, { useEffect, useState } from "react"
import moment from "moment"

const Day = ({ day, className, onClick }) => {

    const now = moment().unix()

    const [nbHeures, setNbHeures] = useState(0)
    const [aVenir, setAVenir] = useState(null)

    useEffect(() => {
        if (day.lecons.length === 0) {
            setNbHeures(0)
            setAVenir(null)
            return
        }
        let s = 0
        day.lecons.map(lecon => s += (lecon.fin - lecon.debut))
        setNbHeures(s / 3600)
        setAVenir(day.lecons[day.lecons.length - 1].fin > now)
    }, [day])

    return (
        <div
            className={`
            ${className}
            ${aVenir === null ? "" : aVenir ? "coursNext" : "coursPrev"}
            ${!day.examen ? "" : "examen"}
            `}
            onClick={onClick}
        >
            {nbHeures > 0 && <div className="pastille"> {nbHeures}h  </div>}
            {day.examen && <div className="pastille">Examen {moment.unix(day.examen.date).format("HH:mm")}
            </div>}
            {day.format("DD").toString()}
        </div>
    )
}

export default Day