import React, { useEffect, useState } from 'react'

import { upperFirst, toUpper } from 'lodash'

const LigneMoniteur = ({ moniteur, agences, onClick, onConges }) => {

    const [agence, setAgence] = useState(false)

    useEffect(() => {
        if (moniteur && agences) {
            setAgence(agences[agences.findIndex(e => e.id === moniteur.agence_id)])
        }
    }, [moniteur, agences])

    return (
        <div className='ligneMoniteur' >
            <tr>
                <td className='prenom' width={90} rowSpan={3}>{upperFirst(moniteur.prenom)} {toUpper(moniteur.nom)}</td>
                <td className='nomAgence' rowSpan={3} ><span className={agence.id === 1 ? "capus" : "chartrons"}>{agence.nom}</span></td>
                <td className='horaire jour' colSpan={2}>Lundi</td>
                <td className='horaire jour' colSpan={2}>Mardi</td>
                <td className='horaire jour' colSpan={2}>Mercredi</td>
                <td className='horaire jour' colSpan={2}>Jeudi</td>
                <td className='horaire jour' colSpan={2}>Vendredi</td>
                <td className='horaire jour' colSpan={2}>Samedi</td>
                <td className='boutons' width={90} rowSpan={3}>
                    <button className='creux' onClick={onClick}>Fiche</button>
                    <button onClick={onConges}>Indisponibilités</button>
                </td>
            </tr>
            <tr>
                <td className='horaire ampm'>Matin {moniteur.LuAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.LuPmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Matin {moniteur.MaAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.MaPmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Matin {moniteur.MeAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.MePmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Matin {moniteur.JeAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.JePmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Matin {moniteur.VeAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.VePmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Matin {moniteur.SaAmBA === 1 && <span>BA</span>}</td>
                <td className='horaire ampm'>Aprem {moniteur.SaPmBA === 1 && <span>BA</span>}</td>
            </tr>
            <tr>
                <td className='horaire h'>{moniteur.LuAmDe !== -1 ? `${moniteur.LuAmDe}h à ${moniteur.LuAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.LuPmDe !== -1 ? `${moniteur.LuPmDe}h à ${moniteur.LuPmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.MaAmDe !== -1 ? `${moniteur.MaAmDe}h à ${moniteur.MaAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.MAPmDe !== -1 ? `${moniteur.MaPmDe}h à ${moniteur.MaPmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.MeAmDe !== -1 ? `${moniteur.MeAmDe}h à ${moniteur.MeAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.MePmDe !== -1 ? `${moniteur.MePmDe}h à ${moniteur.MePmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.JeAmDe !== -1 ? `${moniteur.JeAmDe}h à ${moniteur.JeAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.JePmDe !== -1 ? `${moniteur.JePmDe}h à ${moniteur.JePmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.VeAmDe !== -1 ? `${moniteur.VeAmDe}h à ${moniteur.VeAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.VePmDe !== -1 ? `${moniteur.VePmDe}h à ${moniteur.VePmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.SaAmDe !== -1 ? `${moniteur.SaAmDe}h à ${moniteur.SaAmFi}h` : "Repos"}</td>
                <td className='horaire h'>{moniteur.SaPmDe !== -1 ? `${moniteur.SaPmDe}h à ${moniteur.SaPmFi}h` : "Repos"}</td>
            </tr>
        </div>
    )
}

export default LigneMoniteur