import React, { useState } from 'react'
import { connect } from 'react-redux'

import './scss/Connexion.scss'

import Switch from './../components/Switch'

import MILKY from './../images/MilkyW.png'

const Connexion = ({ connexion }) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showMdp, setShowMdp] = useState(false)


    return (
        <div className='connexion'>
            <section>
                    {/* <Outlet /> */}
                    <img src={MILKY} />
                    <h2>Administration</h2>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='email' />
                    <input type={showMdp ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} placeholder='mot de passe' />
                    <Switch label={"Afficher le mot de passe"} initiale={showMdp} devientOn={() => setShowMdp(true)} devientOff={() => setShowMdp(false)} />
                    <button className='forgotPassword' onClick={() => alert("Vous avez oublié votre mot de passe?\nMerci de contacter un autre administrateur")}>Mot de passe oublié?</button>
                    <button onClick={() => connexion(email, password)} style={{ width: '120px' }}>Je me connecte</button>
            </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        connexion: (email, password) => dispatch({ type: `CONNEXION`, email, password }),
    }
}


export default connect(null, mapDispatchToProps)(Connexion)