import React, { useEffect, useState } from "react"

import CreateAdmin from "../components/CreateAdmin"

import { connect } from 'react-redux'

import './scss/Admin.scss'

const Administrateurs = ({ me, getAdministrateurs, administrateurs, delAdmin, setNewM, reInit }) => {

    const [newAdmin, setNewAdmin] = useState(false)
    const [newMdp, setNewMdp] = useState("")

    useEffect(() => {
        getAdministrateurs()
    }, [])

    function _setNewMdp() {
        if (newMdp.length < 3) {
            alert("Vous ne pouvez pas rentrer un mot de passe inférieur à 3 caractères")
            return
        } else {
            setNewM(newMdp, me.id)
            setTimeout(() => {
                setNewMdp("")
            }, 850);
        }

    }

    return (
        <div className="admins">
            {newAdmin && <CreateAdmin close={() => setNewAdmin(false)} />}
            <button onClick={() => setNewAdmin(true)}>Ajouter</button>
            <section>
                {administrateurs.map(admin =>
                    <article key={admin.id}>
                        <div>
                            {admin.prenom} {admin.nom} -> <strong>{admin.email}</strong>
                        </div>
                        <div className="boutons">
                            {admin.id !== me.id && <button className="creux" onClick={() => reInit(admin.id)}>Ré-initialiser</button>}
                            {admin.id === me.id &&
                                <span>
                                    <input type="text" value={newMdp} onChange={e => setNewMdp(e.target.value)} />
                                    <button className="creux" style={{ display: "inline-block" }} onClick={() => _setNewMdp()}>Valider</button>
                                </span>
                            }
                            <button className="saumon creux" onClick={() => delAdmin(admin.id)}>Supprimer</button>
                        </div>
                    </article>
                )}
            </section>
        </div>
    )
}

const mapToProps = state => {
    return {
        me: state.me,
        administrateurs: state.administrateurs || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAdministrateurs: () => dispatch({ type: `GET_ADMINISTRATEURS` }),
        delAdmin: (id) => dispatch({ type: `DEL_ADMIN`, id }),
        setNewM: (mdp, id) => dispatch({ type: `SET_NEW_MDP`, mdp, id }),
        reInit: (id) => dispatch({ type: `RE_INIT`, id }),
    }
}



export default connect(mapToProps, mapDispatchToProps)(Administrateurs)