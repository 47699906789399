import React from "react"

import LOAD from './../images/load.png'

const Loader = () => {
    return (
        <div className="loader">
            <p>Chargement
                <img
                    className="loading"
                    src={LOAD} />
            </p>
        </div>
    )
}

export default Loader