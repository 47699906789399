import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CROSS from './../pictos/cross.svg'

const AjoutHeures = ({ eleveId, close, setExamen }) => {

    const [exam, setExam] = useState(0)
    const [text, setText] = useState("")
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    function validate() {
        const timestamp = Math.floor(exam.getTime() / 1000)
        setExamen(parseInt(eleveId), timestamp, text)
        setTimeout(() => {
            fermer()
        }, 385)
    }

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    return (
        <div className='flottant ajoutHeures' style={{ opacity, height: "inherit" }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1>Date et heure de l'examen?</h1>
            <h2>-</h2>
            <div className='contenu' style={{ flexDirection: "column" }}>
                <input
                    type='datetime-local'
                    onChange={e => setExam(new Date(e.target.value))}
                    style={{ width: 'inherit' }}
                />
                <input type="text" value={text} onChange={e => setText(e.target.value)} placeholder="Commentaire pour cet examen" style={{ width: "100%", marginTop: "15px", marginBottom: "15px"}}/>
                <button onClick={() => validate()}>Valider</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setExamen: (id, timestamp, text) => dispatch({ type: `SET_EXAMEN`, id, timestamp, text }),
    }
}


export default connect(null, mapDispatchToProps)(AjoutHeures)