import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { upperFirst } from 'lodash'

import axios from 'axios'

import CROSS from './../pictos/cross.svg'
import ARROW from './../pictos/arrow.svg'

import MultipleChoice from './MultipleChoice'

import './../pages/scss/Fiche.scss'

const date = new Date()

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
}

const FicheEleve = ({ close, eleve, eleveId, setCoordonnees, agences }) => {

    function bloque(e) {
        if (e.keyCode === 9) {
            e.preventDefault()
            setMoved(true)
        }
    }

    const firstInput = useRef()
    const nextInput = useRef()

    const [opacity, setOpacity] = useState(0)

    const [prenom, setPrenom] = useState(eleve ? eleve.prenom : "")
    const [nom, setNom] = useState(eleve ? eleve.nom : "")
    const [dateNaissance, setDateNaissance] = useState(eleve && eleve.date_naissance !== null ? new Date(eleve.date_naissance) : date)
    const [numRue, setNumRue] = useState(eleve ? eleve.numero_rue : "")
    const [rue, setRue] = useState(eleve ? eleve.rue : "")
    const [codePostal, setCodePostal] = useState(eleve ? eleve.code_postal : "")
    const [ville, setVille] = useState(eleve ? eleve.ville : "")
    const [telephone, setTelephone] = useState(eleve ? eleve.telephone : "")
    const [email, setEmail] = useState(eleve ? eleve.email : "")
    const [villeNaissance, setVilleNaissance] = useState(eleve ? eleve.ville_naissance : "")
    const [paysNaissance, setPaysNaissance] = useState(eleve ? eleve.pays_naissance : "")
    const [neph, setNeph] = useState(eleve ? eleve.neph : "")
    const [creditHeures, setCreditHeures] = useState(eleve ? parseInt(eleve.credit_heures) : 0)
    const [eleveAgence, setEleveAgence] = useState(eleve ? eleve.agence_id : 1)
    const [elevePermis, setElevePermis] = useState(eleve ? eleve.permis_bm : 1)
    const [financement, setFinancement] = useState(eleve ? eleve.type_financement : 0)
    const [conduite, setConduite] = useState(eleve ? eleve.type_conduite : 0)


    const [canSend, setCanSend] = useState(false)
    const [moved, setMoved] = useState(false)

    const [adresseComplete, setAdresseComplete] = useState("")

    useEffect(() => {
        if (!moved && firstInput) {
            firstInput.current.focus()
        }
        if (moved && nextInput) {
            setTimeout(() => {
                nextInput.current.focus()
            }, 500)
        }
    }, [moved])

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    useEffect(() => {
        if (prenom.length > 0 && nom.length > 0 && telephone.length > 0 && email.length > 0 && !isNaN(creditHeures)) {
            setCanSend(true)
        } else {
            setCanSend(false)
        }
    })

    useEffect(() => {
        if (adresseComplete.length === 0) return
        axios.get(`https://api-adresse.data.gouv.fr/search/?q=${adresseComplete.replace(/ /g, "+")}`).then(({ data }) => {
            if (data.features.length === 0) return
            const { properties } = data.features[0]
            setNumRue(properties.housenumber)
            setRue(properties.street)
            setCodePostal(properties.postcode)
            setVille(properties.city)
        })

    }, [adresseComplete])

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    function send() {
        if (!canSend) return
        const eleve = {
            prenom,
            nom,
            numRue,
            dateNaissance,
            rue,
            codePostal,
            ville,
            villeNaissance,
            paysNaissance,
            telephone,
            email,
            neph,
            creditHeures,
            eleveAgence,
            elevePermis,
            id: parseInt(eleveId),
            conduite,
            financement
        }
        setCoordonnees(eleve)
        setTimeout(() => {
            fermer()
        }, 250)
    }


    return (
        <div className='flottant fiche-eleve' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            {eleve ?
                <h1>Modifier les coordonnées de<br />{eleve.prenom} {eleve.nom}</h1> :
                <h1>Création d'une nouvelle fiche élève</h1>
            }
            <div className='niv1'>
                <div className='niv2' style={{ transform: `translateX(${moved ? `-50%` : `0`})` }}>
                    <div className='inpouts page1'>
                        <div>
                            <h2><span>Prénom:</span> <input ref={firstInput} className={prenom.length === 0 ? "vide" : ""} type='text' value={prenom} onChange={e => setPrenom(upperFirst(e.target.value))} /></h2>
                            <h2><span>Nom:</span> <input className={nom.length === 0 ? "vide" : ""} type='text' value={nom} onChange={e => setNom(upperFirst(e.target.value))} /></h2>
                            <h2><span>Adresse complete*</span> <input type='text' value={adresseComplete} placeholder='Saisir une adresse complète' onChange={e => setAdresseComplete(e.target.value)} /></h2>
                            <h2><span>Numéro de rue:</span> <input type='text' value={numRue} onChange={e => setNumRue(e.target.value)} /></h2>
                            <h2><span>Rue:</span> <input type='text' value={rue} onChange={e => setRue(e.target.value)} /></h2>
                            <h2><span>Code postal:</span> <input type='text' value={codePostal} onChange={e => setCodePostal(e.target.value)} /></h2>
                            <h2><span>Ville:</span> <input type='text' value={ville} onChange={e => setVille(e.target.value)} /></h2>
                            <h2><span>Date de naissance: </span><input type='date' onChange={e => setDateNaissance(new Date(e.target.value))} /></h2>
                            <h2><span>Ville<br />naissance:</span> <input type='text' value={villeNaissance} onChange={e => setVilleNaissance(e.target.value)} /></h2>
                            <h2><span>Pays<br />naissance:</span> <input type='text' value={paysNaissance} onChange={e => setPaysNaissance(e.target.value)} /></h2>
                            <h2><span>Téléphone</span> <input className={telephone.length === 0 ? "vide" : ""} type='text' value={telephone} onChange={e => setTelephone(e.target.value)} /></h2>
                            <h2><span>email</span> <input className={email.length === 0 ? "vide" : ""} type='text' value={email} onChange={e => setEmail(e.target.value)} /></h2>
                            <h2><span>NEPH:</span>
                                <input
                                    onKeyDown={e => bloque(e)}
                                    type='text'
                                    value={neph}
                                    onChange={e => setNeph(e.target.value)}
                                />
                            </h2>
                        </div>

                        <div className='arrow' onClick={() => setMoved(true)}>
                            <img src={ARROW} />
                        </div>
                    </div>

                    <div className='inpouts page2'>
                        <div className='arrow' onClick={() => setMoved(false)} style={{ transform: 'rotate(180deg)' }}>
                            <img src={ARROW} />
                        </div>
                        <div className='cols'>
                            <h2><span>Crédit d'heures:</span> <input ref={nextInput} type='text' className={` ${isNaN(creditHeures) ? "vide" : ""}`} value={creditHeures} onChange={e => setCreditHeures(e.target.value)} /></h2>
                            <h2><span>Financement:</span>
                                <MultipleChoice
                                    datas={[
                                        { id: 0, nom: "Personnel" },
                                        { id: 1, nom: "Permis à 1€" },
                                        { id: 2, nom: "F. sociaux" },
                                        { id: 3, nom: "CPF" },
                                        { id: 4, nom: "CFA" }
                                    ]}
                                    current={financement}
                                    setNewValue={e => setFinancement(e)}
                                />
                            </h2>
                            <h2><span>Agence:</span>
                                <MultipleChoice
                                    datas={agences}
                                    current={agences.findIndex(e => e.id === eleveAgence)}
                                    setNewValue={e => setEleveAgence(e)}
                                />
                            </h2>
                            <h2><span>Permis: </span>
                                <MultipleChoice
                                    datas={[{ id: 0, nom: "Permis B / BA" }, { id: 1, nom: "Permis B / BM" }]}
                                    current={elevePermis}
                                    setNewValue={e => setElevePermis(e)}
                                />
                            </h2>
                            <h2><span>Conduite: </span>
                                <MultipleChoice
                                    datas={[
                                        { id: 0, nom: "Classique" },
                                        { id: 1, nom: "Supervisée" },
                                        { id: 2, nom: "Accompagnée" },
                                        { id: 3, nom: "Perfectionnement" }
                                    ]}
                                    current={conduite}
                                    setNewValue={e => setConduite(e)}
                                />
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <button
                style={{ opacity: canSend ? "1" : "0.5" }}
                onClick={() => send()}
            >
                {eleve ? "Valider" : "Créer"}
            </button>
        </div>
    )
}

const mapToProps = state => {
    return {
        agences: state.agences || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCoordonnees: (eleve) => dispatch({ type: `SET_COORDONNEES`, eleve }),
    }
}


export default connect(mapToProps, mapDispatchToProps)(FicheEleve)