import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { lowerCase } from 'lodash'
import * as XLSX from 'xlsx'
import * as FileSaver from "file-saver"

import Switch from '../components/Switch'

import Loader from '../components/Loader'
import LigneEleve from '../components/LineELeve'
import FicheEleve from '../components/FicheEleve'

import './scss/Eleves.scss'


const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

const Eleves = ({ eleves, agences, getEleves }) => {

    const [newStuent, setNewStudent] = useState(false)
    const [listeEleves, setListeEleves] = useState(eleves)
    const [filtreNom, setFiltreNom] = useState("")
    const [showCapus, setShowCapus] = useState(true)
    const [showChartrons, setShowChartrons] = useState(true)
    const [showAnciens, setShowAnciens] = useState(true)

    useEffect(() => {
        getEleves()
    }, [])

    const exportToCSV = (apiData) => {
        const ws = XLSX.utils.json_to_sheet(apiData)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        const date = new Date()
        FileSaver.saveAs(data, `Liste-eleves-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`)
    }

    useEffect(() => {
        let tabEleves = []
        if (filtreNom.length === 0 || filtreNom === "") {
            tabEleves = [...eleves]
        } else {
            eleves.map(eleve => {
                if (lowerCase(eleve.nom).includes(lowerCase(filtreNom)) || lowerCase(eleve.prenom).includes(lowerCase(filtreNom)) || lowerCase(`${eleve.prenom} ${eleve.nom}`).includes(lowerCase(filtreNom)) || lowerCase(`${eleve.nom} ${eleve.prenom}`).includes(lowerCase(filtreNom))) {
                    tabEleves.push(eleve)
                }
            })
        }

        const t = []
        tabEleves.map(e => {
            if (showCapus && e.agence_id === 1) {
                t.push(e)
            }
            if (showChartrons && e.agence_id === 2) {
                t.push(e)
            }
        })
        const tab = []
        t.map(e => {
            if (e.isInscrit === 1) {
                tab.push(e)
            }
            if (showAnciens && e.isInscrit === 0) {
                tab.push(e)
            }
        })
        setListeEleves([...tab])
    }, [eleves, filtreNom, showCapus, showChartrons, showAnciens])

    return (
        <div className='eleves'>
            <header>
                <button onClick={() => setNewStudent(true)}>Ajouter</button>
                <button className='creux' onClick={() => exportToCSV(listeEleves)}>Exporter</button>
                <input type={'text'} placeholder={`Nom et/ou Prénom`} onChange={e => setFiltreNom(e.target.value)} />
                <Switch
                    label={"Les Chartrons"}
                    initiale={showChartrons}
                    devientOn={() => setShowChartrons(true)}
                    devientOff={() => setShowChartrons(false)}
                />
                <Switch
                    label={"Les capus"}
                    initiale={showCapus}
                    devientOn={() => setShowCapus(true)}
                    devientOff={() => setShowCapus(false)}
                />
                <Switch
                    label={"Anciens élèves"}
                    initiale={showAnciens}
                    devientOn={() => setShowAnciens(true)}
                    devientOff={() => setShowAnciens(false)}
                />
                <div className='numbers'>Affchage: {listeEleves.length} / {eleves.length}</div>
            </header>
            {newStuent && <FicheEleve eleve={null} eleveId={null} close={() => setNewStudent(false)} />}
            {eleves.length > 0 ? <table>
                {listeEleves.map(eleve =>
                    <LigneEleve
                        key={eleve.id}
                        eleve={eleve}
                        agence={agences[agences.findIndex(i => i.id === eleve.agence_id)]}
                    />
                )}
            </table> :
                <Loader />
            }
        </div>
    )
}

const mapToProps = state => {
    return {
        eleves: state.eleves || [],
        agences: state.agences || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEleves: () => dispatch({ type: `GET_ELEVES` }),
    }
}




export default connect(mapToProps, mapDispatchToProps)(Eleves)