import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { upperFirst, toUpper } from 'lodash'

const LigneEleve = ({ eleve, agence }) => {
    
    return (
        <tr className={`ligneEleve ${eleve.isInscrit !== 1 ? "radie" : ""}`}>
            <td className='prenom'>{upperFirst(eleve.prenom)}</td>
            <td className='nom'>{toUpper(eleve.nom)}</td>
            <td className='nomAgence' ><span className={agence.id === 1 ? "capus" : "chartrons"}>{agence.nom}</span></td>
            <td className='type-permis'>PERMIS B / {eleve.permis_bm === 1 ? "BM" : "BA"}</td>
            <td className='paye'>payé <strong>{eleve.credit_heures > 0 ? `${eleve.credit_heures}H` : "-"}</strong></td>
            <td className='effectue'>posé <strong>{eleve.ok > 0 ? `${eleve.ok}H` : "-"}</strong></td>
            <td className='effectue'>restant du <strong>{eleve.restant > 0 ? `${eleve.restant}H` : "-"}</strong></td>
            <td className='absent'>absent <strong>{eleve.absent > 0 ? `${eleve.absent}H` : "-"}</strong></td>
            <td className='fiche'><Link to={`/eleve/${eleve.id}`}>Voir la fiche</Link></td>
        </tr>
    )
}

export default LigneEleve