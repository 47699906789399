import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CROSS from './../pictos/cross.svg'

const AjoutHeures = ({ eleveId, close, setH }) => {

    const [heures, setHeures] = useState(10)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    function _setHeures() {
        if (isNaN(heures)) {
            alert("Vous devez entrer un nombre")
            return
        }
        setH(eleveId, heures)
        fermer()
    }

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    return (
        <div className='flottant ajoutHeures' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1>Combien d'heures à rajouter?</h1>
            <h2>Pour soustraire des heures, mettez un signe "-" devant</h2>
            <div className='contenu'>
                <input type="text" value={heures} onChange={e => setHeures(e.target.value)} />
                <button onClick={() => _setHeures()}>Valider</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setH: (id, heures) => dispatch({ type: `SET_HEURES`, id, heures }),
    }
}


export default connect(null, mapDispatchToProps)(AjoutHeures)