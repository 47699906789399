const months = ['jan', 'fev', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'dec']

export function jours(i) {
    const j = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
    return j[i]
}

export function TimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000)
    const m = date.getMonth() + 1
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const mois = m < 10 ? `0${m}` : m
    return `${jour}/${mois}/${date.getFullYear()}`
}

export function TimestampToDayMonth(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${jour} ${mois}`
}

export function TimestampToDayMonthYear(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${jour} ${mois} ${date.getUTCFullYear()}`
}

export function TimestampToDayMonthYearHoursMinutes(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const heures = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${jour} ${mois} ${date.getUTCFullYear()} à ${heures}h${minutes}`
}

export function formatHeure(float) {
    const isInt = Number.isInteger(float)

    if (isInt) {
        return `${float}:00`
    } else {
        return `${Math.floor(float)}:30`
    }
}

export function Hours(timestamp) {
    const date = new Date(timestamp * 1000)
    const heure = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${heure}:${minute}`
}

export function Jour(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const week = jours(date.getDay())
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${week} ${jour} ${mois} ${date.getUTCFullYear()}`
}