import React, { useState, useEffect } from 'react'
import moment from "moment"

import BuildCalendar from './BuildCalendar'
import Day from './Day'
import dayStyles from "./styles"

import './Calendrier.scss'

const jours = ["L", "M", "M", "J", "V", "S", "D"]
const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

const examen = {
    date: 1641891600,
    eleve: 12
}

const Calendrier = ({ eleve }) => {

    const [calendar, setCalendar] = useState([])
    const [value, setValue] = useState(moment())
    const [currentDay, setCurrentDay] = useState(false)


    useEffect(() => {
        setCalendar(BuildCalendar(value, eleve.lessons, examen))
    }, [value])

    function prevMonth() {
        setValue(value.clone().subtract(1, "month").startOf("month"))
    }

    function nextMonth() {
        setValue(value.clone().add(1, "month").startOf("month"))
    }

    return (
        <div className='calendrier'>
            <header>
                <button onClick={() => prevMonth()} className='creux'>Précedent</button>
                {mois[parseInt(value.format("M")) - 1]} {value.format("YYYY")}
                <button onClick={() => nextMonth()} className='creux'>Suivant</button>
            </header>
            <div className="semaine">
                {jours.map((jour, i) => <div key={i}>{jour}</div>)}
            </div>
            {calendar.map((w, i) =>
                <div className="semaine" key={i}>
                    {w.map((d, j) =>
                        <Day
                            key={j}
                            className={dayStyles(d, value)}
                            day={d}
                            onClick={() => {
                                setCurrentDay(d)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default Calendrier