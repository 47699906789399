import React, { useEffect, useState } from 'react'
import { formatHeure } from '../functions/utils'
import FicheAnnulation from './ConfirmAnnulation'

const className = ({ indispo, lecon, isError, isAnnulee }) => {
    if (indispo) {
        return 'indispo'
    } else if (isError) {
        return 'isError'
    } else if (isAnnulee) {
        return 'isAnnulee'
    } else if (lecon) {
        return 'lecon'
    } else {
        return 'dispo'
    }
}

const Cellule = ({ ampm, show, delLecon, createLecon }) => {

    const [showConfirm, setShowConfirm] = useState(false)

    function action() {
        if(ampm.isAnnulee){
            alert("L'élève est absent ->\nVous devez supprimer ce créneau depuis sa fiche élève")
            return
        }
        if (ampm.lecon) {
            const now = new Date()
            const nowTs = Math.floor(now.getTime() / 1000)
            if (ampm.hDebTs - nowTs <= 3600 * 24 * 2) {
                setShowConfirm(true)
            } else {
                delLecon(ampm.hDebTs, ampm.hFinTs, ampm.idMoniteur, ampm.lecon.idEleve, 0, ampm.BA)
                setTimeout(() => {
                    setShowConfirm(false)
                }, 850)
            }
        } else if (!ampm.indispo) {
            createLecon(ampm.hDebTs, ampm.hFinTs, ampm.idMoniteur, ampm.BA, 0, ampm.BA)
        }
    }

    function delOui() {
        delLecon(ampm.hDebTs, ampm.hFinTs, ampm.idMoniteur, ampm.lecon.idEleve, 1, ampm.BA)
        setTimeout(() => {
            setShowConfirm(false)
        }, 850)
    }

    function delNon() {
        delLecon(ampm.hDebTs, ampm.hFinTs, ampm.idMoniteur, ampm.lecon.idEleve, 0, ampm.BA)
        setTimeout(() => {
            setShowConfirm(false)
        }, 850)
    }

    return (
        <div>
            {showConfirm && <FicheAnnulation fermer={() => setShowConfirm(false)} oui={() => delOui()} non={() => delNon()} />}
            <div
                className={`cellule ${className(ampm)}`}
                style={{ transform: `scaleY(${show ? 1 : 0})` }}
                onClick={() => action()}
            >
                {ampm.BA && <span className='ba'>BA</span>}
                <div className='horaire'>{formatHeure(ampm.hDeb)} à {formatHeure(ampm.hFin)}</div>
                {ampm.lecon ?
                    <div className='eleve'>{ampm.lecon.prenomEleve} {ampm.lecon.nomEleve}</div> :
                    <div className='status'>{ampm.indispo ? "Indisponible" : "Libre"}</div>
                }
                <div className='moniteur'>
                    {ampm.isError && <div>
                        <strong>Cliquez pour annuler la leçon</strong>
                    </div>}
                    {ampm.prenomMoniteur} {ampm.nomMoniteur}
                    {ampm.isError &&
                        <div>
                            n'est pas disponible pour assurer cette leçon:
                            <br />ses horaires de travail ont probablement été changés
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default Cellule