import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { upperFirst } from 'lodash'

import Switch from './Switch'

import axios from 'axios'

import MultipleChoice from './MultipleChoice'

import CROSS from './../pictos/cross.svg'
import ARROW from './../pictos/arrow.svg'

import './../pages/scss/Fiche.scss'

const date = new Date()

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
}

const FicheMoniteur = ({ close, agences, moniteur, setCoordonnees, exist, reInit }) => {

    const firstInput = useRef()
    const nextInput = useRef()


    const [opacity, setOpacity] = useState(0)

    const [id, setId] = useState(moniteur ? moniteur.id : -1)
    const [prenom, setPrenom] = useState(moniteur ? moniteur.prenom : "")
    const [nom, setNom] = useState(moniteur ? moniteur.nom : "")
    const [numRue, setNumRue] = useState(moniteur ? moniteur.numero_rue : "")
    const [rue, setRue] = useState(moniteur ? moniteur.rue : "")
    const [codePostal, setCodePostal] = useState(moniteur ? moniteur.code_postal : "")
    const [ville, setVille] = useState(moniteur ? moniteur.ville : "")
    const [telephone, setTelephone] = useState(moniteur && moniteur.telephone !== null ? moniteur.telephone : "")
    const [email, setEmail] = useState(moniteur && moniteur.email !== null ? moniteur.email : "")
    const [dateNaissance, setDateNaissance] = useState(moniteur && moniteur.date_naissance !== null ? new Date(moniteur.date_naissance) : date)
    const [numEnseignant, setNumEnseignant] = useState(moniteur && moniteur.num_enseignant !== null ? moniteur.num_enseignant : "")
    const [dateValiditeAgrement, setDateValiditeAgrement] = useState(moniteur && moniteur.date_validite_agrement !== null ? new Date(moniteur.date_validite_agrement * 1000) : date.getTime())
    const [agence, setAgence] = useState(moniteur ? moniteur.agence_id : 1)

    const [LuAmDe, setLuAmDe] = useState(moniteur ? moniteur.LuAmDe : -1)
    const [LuAmFi, setLuAmFi] = useState(moniteur ? moniteur.LuAmFi : -1)
    const [LuAmBA, setLuAmBA] = useState(moniteur ? moniteur.LuAmBA : 0)
    const [LuPmDe, setLuPmDe] = useState(moniteur ? moniteur.LuPmDe : -1)
    const [LuPmFi, setLuPmFi] = useState(moniteur ? moniteur.LuPmFi : -1)
    const [LuPmBA, setLuPmBA] = useState(moniteur ? moniteur.LuPmBA : 0)


    const [MaAmDe, setMaAmDe] = useState(moniteur ? moniteur.MaAmDe : -1)
    const [MaAmFi, setMaAmFi] = useState(moniteur ? moniteur.MaAmFi : -1)
    const [MaAmBA, setMaAmBA] = useState(moniteur ? moniteur.MaAmBA : 0)
    const [MaPmDe, setMaPmDe] = useState(moniteur ? moniteur.MaPmDe : -1)
    const [MaPmFi, setMaPmFi] = useState(moniteur ? moniteur.MaPmFi : -1)
    const [MaPmBA, setMaPmBA] = useState(moniteur ? moniteur.MaPmBA : 0)


    const [MeAmDe, setMeAmDe] = useState(moniteur ? moniteur.MeAmDe : -1)
    const [MeAmFi, setMeAmFi] = useState(moniteur ? moniteur.MeAmFi : -1)
    const [MeAmBA, setMeAmBA] = useState(moniteur ? moniteur.MeAmBA : 0)
    const [MePmDe, setMePmDe] = useState(moniteur ? moniteur.MePmDe : -1)
    const [MePmFi, setMePmFi] = useState(moniteur ? moniteur.MePmFi : -1)
    const [MePmBA, setMePmBA] = useState(moniteur ? moniteur.MePmBA : 0)


    const [JeAmDe, setJeAmDe] = useState(moniteur ? moniteur.JeAmDe : -1)
    const [JeAmFi, setJeAmFi] = useState(moniteur ? moniteur.JeAmFi : -1)
    const [JeAmBA, setJeAmBA] = useState(moniteur ? moniteur.JeAmBA : 0)
    const [JePmDe, setJePmDe] = useState(moniteur ? moniteur.JePmDe : -1)
    const [JePmFi, setJePmFi] = useState(moniteur ? moniteur.JePmFi : -1)
    const [JePmBA, setJePmBA] = useState(moniteur ? moniteur.JePmBA : 0)


    const [VeAmDe, setVeAmDe] = useState(moniteur ? moniteur.VeAmDe : -1)
    const [VeAmFi, setVeAmFi] = useState(moniteur ? moniteur.VeAmFi : -1)
    const [VeAmBA, setVeAmBA] = useState(moniteur ? moniteur.VeAmBA : 0)
    const [VePmDe, setVePmDe] = useState(moniteur ? moniteur.VePmDe : -1)
    const [VePmFi, setVePmFi] = useState(moniteur ? moniteur.VePmFi : -1)
    const [VePmBA, setVePmBA] = useState(moniteur ? moniteur.VePmBA : 0)


    const [SaAmDe, setSaAmDe] = useState(moniteur ? moniteur.SaAmDe : -1)
    const [SaAmFi, setSaAmFi] = useState(moniteur ? moniteur.SaAmFi : -1)
    const [SaAmBA, setSaAmBA] = useState(moniteur ? moniteur.SaAmBA : 0)
    const [SaPmDe, setSaPmDe] = useState(moniteur ? moniteur.SaPmDe : -1)
    const [SaPmFi, setSaPmFi] = useState(moniteur ? moniteur.SaPmFi : -1)
    const [SaPmBA, setSaPmBA] = useState(moniteur ? moniteur.SaPmBA : 0)

    const [canSend, setCanSend] = useState(true)
    const [moved, setMoved] = useState(false)

    const [adresseComplete, setAdresseComplete] = useState("")

    const [newMdp, setNewMdp] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    useEffect(() => {
        if (!moved && firstInput) {
            firstInput.current.focus()
        }
        if (moved && nextInput) {
            setTimeout(() => {
                nextInput.current.focus()
            }, 500)
        }
    }, [moved])

    function bloque(e) {
        if (e.keyCode === 9) {
            e.preventDefault()
            setMoved(true)
        }
    }

    useEffect(() => {
        if (adresseComplete.length === 0) return
        axios.get(`https://api-adresse.data.gouv.fr/search/?q=${adresseComplete.replace(/ /g, "+")}`).then(({ data }) => {
            if (data.features.length === 0) return
            const { properties } = data.features[0]
            setNumRue(properties.housenumber)
            setRue(properties.street)
            setCodePostal(properties.postcode)
            setVille(properties.city)
        })

    }, [adresseComplete])

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    function send() {
        if (!canSend) return
        const moniteur = {
            id,
            prenom,
            nom,
            numRue,
            rue,
            codePostal,
            ville,
            telephone,
            email,
            dateNaissance,
            numEnseignant,
            dateValiditeAgrement,
            agence,
            LuAmDe,
            LuAmFi,
            LuAmBA,
            LuPmDe,
            LuPmFi,
            LuPmBA,
            MaAmDe,
            MaAmFi,
            MaAmBA,
            MaPmDe,
            MaPmFi,
            MaPmBA,
            MeAmDe,
            MeAmFi,
            MeAmBA,
            MePmDe,
            MePmFi,
            MePmBA,
            JeAmDe,
            JeAmFi,
            JeAmBA,
            JePmDe,
            JePmFi,
            JePmBA,
            VeAmDe,
            VeAmFi,
            VeAmBA,
            VePmDe,
            VePmFi,
            VePmBA,
            SaAmDe,
            SaAmFi,
            SaAmBA,
            SaPmDe: parseFloat(SaPmDe),
            SaPmFi: parseFloat(SaPmFi),
            SaPmBA,

        }
        setCoordonnees(moniteur)
        setTimeout(() => {
            fermer()
        }, 250)
    }

    useEffect(() => {
        if (prenom.length > 0 && nom.length > 0 && telephone.length > 0 && email.length > 0) {
            setCanSend(true)
        } else {
            setCanSend(false)
        }
    })

    function _reInit() {
        if(newMdp.length>3){
            reInit(moniteur.id, newMdp)
            setTimeout(() => {
                close()
            }, 850)
        }else{
            alert("Le mot de passe doit faire au minimum 3 caractères")
        }
    }


    return (
        <div className='flottant fiche-moniteur' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <h1>Modifier la fiche de<br />{moniteur.prenom} {moniteur.nom}</h1>
            <div className='niv1'>
                <div className='niv2' style={{ transform: `translateX(${moved ? `-50%` : `0`})` }}>
                    <div className='inpouts page1'>
                        <div>
                            <h2><span>Prénom:</span> <input ref={firstInput} className={prenom.length === 0 ? "vide" : ""} type='text' value={prenom} onChange={e => setPrenom(upperFirst(e.target.value))} /></h2>
                            <h2><span>Nom:</span> <input className={nom.length === 0 ? "vide" : ""} type='text' value={nom} onChange={e => setNom(upperFirst(e.target.value))} /></h2>
                            <h2><span>Adresse complete*</span> <input type='text' value={adresseComplete} placeholder='Saisir une adresse complète' onChange={e => setAdresseComplete(e.target.value)} /></h2>
                            <h2><span>Numéro de rue:</span> <input type='text' value={numRue} onChange={e => setNumRue(e.target.value)} /></h2>
                            <h2><span>Rue:</span> <input type='text' value={rue} onChange={e => setRue(e.target.value)} /></h2>
                            <h2><span>Code postal:</span> <input type='text' value={codePostal} onChange={e => setCodePostal(e.target.value)} /></h2>
                            <h2><span>Ville:</span> <input type='text' value={ville} onChange={e => setVille(e.target.value)} /></h2>
                            <h2><span>Téléphone</span> <input className={telephone.length === 0 ? "vide" : ""} type='text' value={telephone} onChange={e => setTelephone(e.target.value)} /></h2>
                            <h2><span>email</span> <input className={email.length === 0 ? "vide" : ""} type='text' value={email} onChange={e => setEmail(e.target.value)} /></h2>
                            <h2><span>Date de naissance</span> <input type='date' value={formatDate(dateNaissance)} onChange={e => setDateNaissance(new Date(e.target.value))} /></h2>
                            <h2>
                                <span
                                    style={{ paddingRight: '25px' }}
                                >Agence:</span>
                                <MultipleChoice
                                    datas={agences}
                                    current={agences.findIndex(e => e.id === agence)}
                                    setNewValue={exist ? () => alert("Vous ne pouvez pas changer un moniteur d'agence") : (e => setAgence(e))}
                                />
                            </h2>
                            <h2><span>Numéro d'enseignant</span> <input type='text' value={numEnseignant} onChange={e => setNumEnseignant(e.target.value)} /></h2>
                            <h2><span>Date validité agrément</span> <input type='date' onKeyDown={e => bloque(e)} value={formatDate(new Date(dateValiditeAgrement))} onChange={e => setDateValiditeAgrement(new Date(e.target.value))} /></h2>
                        </div>

                        <div className='arrow' onClick={() => setMoved(true)}>
                            <img src={ARROW} />
                        </div>
                    </div>

                    <div className='inpouts page2'>
                        <div className='arrow' onClick={() => setMoved(false)} style={{ transform: 'rotate(180deg)' }}>
                            <img src={ARROW} />
                        </div>
                        <div className='cols'>
                            <div className='jour'>
                                <h2>Lundi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" ref={nextInput} value={LuAmDe} onChange={(e) => setLuAmDe(e.target.value)} /> à <input type="text" value={LuAmFi} onChange={(e) => setLuAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={LuAmBA === 1} devientOn={() => setLuAmBA(1)} devientOff={() => setLuAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={LuPmDe} onChange={(e) => setLuPmDe(e.target.value)} /> à <input type="text" value={LuPmFi} onChange={(e) => setLuPmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={LuPmBA === 1} devientOn={() => setLuPmBA(1)} devientOff={() => setLuPmBA(0)} />
                                </div>
                            </div>

                            <div className='jour'>
                                <h2>Mardi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" value={MaAmDe} onChange={(e) => setMaAmDe(e.target.value)} /> à <input type="text" value={MaAmFi} onChange={(e) => setMaAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={MaAmBA === 1} devientOn={() => setMaAmBA(1)} devientOff={() => setMaAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={MaPmDe} onChange={(e) => setMaPmDe(e.target.value)} /> à <input type="text" value={MaPmFi} onChange={(e) => setMaPmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={MaPmBA === 1} devientOn={() => setMaPmBA(1)} devientOff={() => setMaPmBA(0)} />
                                </div>
                            </div>

                            <div className='jour'>
                                <h2>Mercredi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" value={MeAmDe} onChange={(e) => setMeAmDe(e.target.value)} /> à <input type="text" value={MeAmFi} onChange={(e) => setMeAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={MeAmBA === 1} devientOn={() => setMeAmBA(1)} devientOff={() => setMeAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={MePmDe} onChange={(e) => setMePmDe(e.target.value)} /> à <input type="text" value={MePmFi} onChange={(e) => setMePmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={MePmBA === 1} devientOn={() => setMePmBA(1)} devientOff={() => setMePmBA(0)} />
                                </div>
                            </div>

                            <div className='jour'>
                                <h2>Jeudi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" value={JeAmDe} onChange={(e) => setJeAmDe(e.target.value)} /> à <input type="text" value={JeAmFi} onChange={(e) => setJeAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={JeAmBA === 1} devientOn={() => setJeAmBA(1)} devientOff={() => setJeAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={JePmDe} onChange={(e) => setJePmDe(e.target.value)} /> à <input type="text" value={JePmFi} onChange={(e) => setJePmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={JePmBA === 1} devientOn={() => setJePmBA(1)} devientOff={() => setJePmBA(0)} />
                                </div>
                            </div>

                            <div className='jour'>
                                <h2>Vendredi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" value={VeAmDe} onChange={(e) => setVeAmDe(e.target.value)} /> à <input type="text" value={VeAmFi} onChange={(e) => setVeAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={VeAmBA === 1} devientOn={() => setVeAmBA(1)} devientOff={() => setVeAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={VePmDe} onChange={(e) => setVePmDe(e.target.value)} /> à <input type="text" value={VePmFi} onChange={(e) => setVePmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={VePmBA === 1} devientOn={() => setVePmBA(1)} devientOff={() => setVePmBA(0)} />
                                </div>
                            </div>

                            <div className='jour'>
                                <h2>Samedi</h2>
                                <div className='ampm'>
                                    <h3>Matin</h3>
                                    <input type="text" value={SaAmDe} onChange={(e) => setSaAmDe(e.target.value)} /> à <input type="text" value={SaAmFi} onChange={(e) => setSaAmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={SaAmBA === 1} devientOn={() => setSaAmBA(1)} devientOff={() => setSaAmBA(0)} />
                                </div>
                                <div className='ampm'>
                                    <h3>Après midi</h3>
                                    <input type="text" value={SaPmDe} onChange={(e) => setSaPmDe(e.target.value)} /> à <input type="text" value={SaPmFi} onChange={(e) => setSaPmFi(e.target.value)} />
                                    <Switch label={"BA"} initiale={SaPmBA === 1} devientOn={() => setSaPmBA(1)} devientOff={() => setSaPmBA(0)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='newMdp'>
                <input type="text" value={newMdp} placeholder="Nouveau mot de passe..." onChange={e => setNewMdp(e.target.value)} />
                <button
                    onClick={() => _reInit()}
                >
                    Valider
                </button>
            </div>
            <button
                style={{ opacity: canSend ? "1" : "0.5" }}
                onClick={() => send()}
            >
                Créer
            </button>
        </div>
    )
}

const mapToProps = state => {
    return {
        agences: state.agences || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCoordonnees: (moniteur) => dispatch({ type: `SET_MONITEUR`, moniteur }),
        reInit: (id, mdp) => dispatch({ type: 'RE_INIT_MONIT', id, mdp })
    }
}


export default connect(mapToProps, mapDispatchToProps)(FicheMoniteur)