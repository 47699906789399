import '@babel/polyfill';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects'
import { apiCall, logOut } from './apiCall'


function* connexion(email, password) {
    let { me } = yield call(apiCall, "POST", "/login", { email, password })
    yield put({ type: 'CONNEXION_ASYNC', isLog: true, me })
}

function* deconnexion() {
    let { logout } = yield call(logOut)
    yield put({ type: 'CONNEXION_ASYNC', isLog: !logout })
}

function* getGeneriques() {
    let { agences } = yield call(apiCall, "GET", `/getGeneriques`)
    yield put({ type: 'GET_GENERIQUES_ASYNC', agences })
}

function* getEleves() {
    let { eleves } = yield call(apiCall, "GET", `/getEleves`)
    yield put({ type: 'GET_ELEVES_ASYNC', eleves })
}

function* getEleve(id) {
    let { eleve } = yield call(apiCall, "GET", `/getEleve/${id}`)
    yield put({ type: 'GET_ELEVE_ASYNC', eleve })
}

function* getCalendrierEleve(id) {
    let { calendrier } = yield call(apiCall, "GET", `/getCalendrierEleve/${id}`)
    yield put({ type: 'GET_CALENDRIER_ELEVE_ASYNC', calendrier })
}

function* setHeures(id, heures) {
    let { eleve } = yield call(apiCall, "POST", "/setHeures", { id, heures })
    yield put({ type: 'GET_ELEVE_ASYNC', eleve })
}

function* setCoordonnees(e) {
    if (isNaN(e.id)) {
        let { eleves } = yield call(apiCall, "POST", "/createStudent", { eleve: e })
        yield put({ type: 'GET_ELEVES_ASYNC', eleves })
    } else {
        let { eleve } = yield call(apiCall, "POST", "/setCoordonnees", { eleve: e })
        yield put({ type: 'GET_ELEVE_ASYNC', eleve })
    }
}

function* getMoniteurs() {
    let { moniteurs } = yield call(apiCall, "POST", "/getMoniteurs")
    yield put({ type: 'GET_MONITEURS_ASYNC', moniteurs })
}
function* setMoniteur(moniteur) {
    let { moniteurs } = yield call(apiCall, "POST", "/setMoniteur", { moniteur })
    yield put({ type: 'GET_MONITEURS_ASYNC', moniteurs })
}

function* getLecons(agence_id, timestamp) {
    let { lecons } = yield call(apiCall, "POST", "/getLecons", { agence_id, timestamp })
    yield put({ type: 'GET_LECONS_ASYNC', lecons })
}

function* getConges(moniteur_id) {
    let { indispos } = yield call(apiCall, "POST", "/getIndispos", { moniteur_id })
    yield put({ type: 'GET_INDISPOS_ASYNC', indispos })
}

function* setIndispos({ dates }, moniteur_id) {
    let { indispos } = yield call(apiCall, "POST", "/setIndispos", { dates, moniteur_id })
    yield put({ type: 'GET_INDISPOS_ASYNC', indispos })
}

function* delIndispo(id, moniteur_id) {
    let { indispos } = yield call(apiCall, "POST", "/delIndispo", { id, moniteur_id })
    yield put({ type: 'GET_INDISPOS_ASYNC', indispos })
}

function* delLecon(hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence) {
    const boiteAuto = BA ? 1 : 0
    yield call(apiCall, "POST", "/delLecon", { hDebTs, hFinTs, idMoniteur, idEleve, avertir, boiteAuto, idAgence })
}

function* setLecon(datas, agenceCalendrier) {
    let { retour } = yield call(apiCall, "POST", "/setLeconAdmin", { datas })
}

// function* setAbsentPresent(prevenir, id) {
//     yield call(apiCall, "POST", "/setLeconStatus", {prevenir,  id })
// }

function* setExamen(id, timestamp, text) {
    yield call(apiCall, "POST", "/setExamen", { id, timestamp, text })
}

function* setCode(id, timestamp) {
    const { eleve } = yield call(apiCall, "POST", "/setCode", { id, timestamp })
    yield put({ type: 'GET_ELEVE_ASYNC', eleve })
}

function* getExamens(id) {
    let { examens } = yield call(apiCall, "POST", "/getExamens", { eleveId: id })
    yield put({ type: 'GET_EXAMENS_ASYNC', examens })
}

function* annuleExam(id) {
    yield call(apiCall, "POST", "/annuleExam", { id })
}

function* setMaxLessons(agenceId, lecons, semaines) {
    let { agences } = yield call(apiCall, "POST", "/setMaxLessons", { agenceId, lecons, semaines })
    yield put({ type: 'GET_GENERIQUES_ASYNC', agences })
}

function* getAdministrateurs() {
    let { administrateurs } = yield call(apiCall, "GET", "/getAdministrateurs")
    yield put({ type: 'GET_ADMINISTRATEURS_ASYNC', administrateurs })
}

function* createAdmin(prenom, nom, email) {
    let { administrateurs } = yield call(apiCall, "POST", "/createAdmin", { prenom, nom, email })
    yield put({ type: 'GET_ADMINISTRATEURS_ASYNC', administrateurs })
}

function* delAdmin(id) {
    let { administrateurs } = yield call(apiCall, "POST", "/delAdmin", { id })
    yield put({ type: 'GET_ADMINISTRATEURS_ASYNC', administrateurs })
}

function* setCommentaire(commentaire, id) {
    let { eleve } = yield call(apiCall, "POST", "/updateComm", { commentaire, id })
    yield put({ type: 'GET_ELEVE_ASYNC', eleve })
}

function* setRadie(id) {
    yield call(apiCall, "POST", "/setRad", { id })
}

function* setNewMdp(mdp, id) {
    let { retour } = yield call(apiCall, "POST", "/setAdPass", { mdp })
    if (retour) {
        alert("Votre mot de passe a bien été changé")
    }
}

function* reInit(id) {
    let { retour, email } = yield call(apiCall, "POST", "/reInitAd", { id })
    if (retour) {
        alert(`Un email avec le nouveau mot de passe a bien été envoyé à ${email}`)
    }
}

function* reInitMonit(id, mdp) {
    let { retour, email } = yield call(apiCall, "POST", "/reInitMo", { id, mdp })
    if (retour) {
        alert(`Un email avec le nouveau mot de passe a bien été envoyé à ${email}`)
    }
}

function* supprimeDefinitivement(id, email) {
    let { mail } = yield call(apiCall, "POST", "/supprimeDefinitivement", { id, email })
}

function* setAbsent(id, debut, avertir) {
    yield call(apiCall, "POST", "/setAbsent", { id, debut, avertir })
}

function* setPresent(id) {
    yield call(apiCall, "POST", "/setPresent", { id })
}


export default function* asyncCalls() {
    yield takeEvery('CONNEXION', ({ email, password }) => connexion(email, password))
    yield takeEvery('DECONNEXION', () => deconnexion())
    yield takeEvery('GET_GENERIQUES', () => getGeneriques())
    yield takeEvery('GET_ELEVES', () => getEleves())
    yield takeEvery('GET_ELEVE', ({ id }) => getEleve(id))
    yield takeEvery('GET_CALENDRIER_ELEVE', ({ id }) => getCalendrierEleve(id))
    yield takeEvery('SET_HEURES', ({ id, heures }) => setHeures(id, heures))
    yield takeEvery('SET_COORDONNEES', ({ eleve }) => setCoordonnees(eleve))
    yield takeEvery('GET_MONITEURS', () => getMoniteurs())
    yield takeEvery('SET_MONITEUR', ({ moniteur }) => setMoniteur(moniteur))
    yield takeEvery('GET_LECONS', ({ agence_id, timestamp }) => getLecons(agence_id, timestamp))
    yield takeEvery('GET_INDISPOS', ({ moniteur_id }) => getConges(moniteur_id))
    yield takeEvery('SET_INDISPOS', ({ dates, moniteur_id }) => setIndispos(dates, moniteur_id))
    yield takeEvery('DEL_INDISPO', ({ id, moniteur_id }) => delIndispo(id, moniteur_id))
    yield takeEvery('DEL_LECON', ({ hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence }) => delLecon(hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence))
    yield takeEvery('SET_LECON', ({ datas, agenceCalendrier }) => setLecon(datas, agenceCalendrier))
    // yield takeLatest('SET_ABSENT_PRESENT', ({ prevenir, id }) => setAbsentPresent(prevenir, id))
    yield takeEvery('SET_EXAMEN', ({ id, timestamp, text }) => setExamen(id, timestamp, text))
    yield takeEvery('SET_CODE', ({ id, timestamp }) => setCode(id, timestamp))
    yield takeEvery('GET_EXAMENS', ({ id }) => getExamens(id))
    yield takeEvery('ANNULE_EXAM', ({ id }) => annuleExam(id))
    yield takeEvery('SET_MAX_LESSONS', ({ agenceId, lecons, semaines }) => setMaxLessons(agenceId, lecons, semaines))
    yield takeEvery('GET_ADMINISTRATEURS', () => getAdministrateurs())
    yield takeEvery('CREATE_ADMIN', ({ prenom, nom, email }) => createAdmin(prenom, nom, email))
    yield takeEvery('DEL_ADMIN', ({ id }) => delAdmin(id))
    yield takeEvery('SET_COMMENTAIRE', ({ commentaire, id }) => setCommentaire(commentaire, id))
    yield takeEvery('RADIE', ({ id }) => setRadie(id))
    yield takeEvery('SET_NEW_MDP', ({ mdp, id }) => setNewMdp(mdp, id))
    yield takeEvery('RE_INIT', ({ id }) => reInit(id))
    yield takeEvery('RE_INIT_MONIT', ({ id, mdp }) => reInitMonit(id, mdp))
    yield takeEvery('SUPPRIME_DEFINITIVEMENT', ({ id, email }) => supprimeDefinitivement(id, email))
    yield takeLatest('SET_ABSENT', ({ id, debut, avertir }) => setAbsent(id, debut, avertir))
    yield takeLatest('SET_PRESENT', ({ id }) => setPresent(id))
}