
import { connect } from 'react-redux'
import Connexion from './pages/Connexion'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import './App.scss'

import Structure from './Structure'
import Lecons from './pages/Lecons'
import Eleves from './pages/Eleves'
import Eleve from './pages/Eleve'
import Moniteurs from './pages/Moniteurs'
import Agences from './pages/Agences'
import Administrateurs from './pages/Administrateurs'

const App = ({ isLog, deconnexion }) => {

  if (!isLog) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Connexion />}>
            <Route path="/connection-fail" element={<p>Mauvais login / mot de passe</p>} />
            <Route path="*" element={<p>Page non trouvée</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Structure deconnexion={deconnexion} />} >
            <Route index element={<Lecons />} />
            <Route path='/eleves' index element={<Eleves />} />
            <Route path='/eleve/:eleveId' element={<Eleve />} />
            <Route path='/moniteurs' element={<Moniteurs />} />
            <Route path='/agences' element={<Agences />} />
            <Route path='/administrateurs' element={<Administrateurs />} />
            <Route path="*" element={<p>Not found...</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

const mapToProps = state => {
  return {
    isLog: state.isLog
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deconnexion: () => dispatch({ type: `DECONNEXION` })
  }
}


export default connect(mapToProps, mapDispatchToProps)(App)
