import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Loader from '../components/Loader'
import MultipleChoice from './../components/MultipleChoice'
import { TimestampToDayMonth, TimestampToDayMonthYear } from '../functions/utils'

import Cellule from '../components/Cellule'
import Switch from '../components/Switch'
import FicheLecon from '../components/FicheLecon'

import ARROW from './../pictos/arrow.svg'

import './scss/Lecons.scss'

const jour = 60 * 60 * 24 * 1000
const semaine = 7 * jour


const Lecons = ({ agenceCalendrier, setAgenceCalendrier, agences, lecons, eleves, getLecons, delLecon, setLecon, getEleves }) => {

    const [currentDate, setCurrentDate] = useState(0) //C'est un timestamp
    const [agence, setAgence] = useState(false)

    const [showLibres, setShowLibres] = useState(true)
    const [showReserves, setShowReserves] = useState(true)
    const [showConges, setShowConges] = useState(true)
    const [showFenetresEleves, setShowFenetresEleves] = useState(false) //false ou 1: BM ou 0: BA

    useEffect(() => {
        const date = new Date()
        if (date.getDay() === 0) {
            setCurrentDate(date.getTime() + jour)
            return
        }
        setCurrentDate(date.getTime())

        getEleves()
    }, [])

    useEffect(() => {
        if (currentDate === 0) return
        getLecons(agenceCalendrier, currentDate)
    }, [currentDate, agenceCalendrier])

    useEffect(() => {
        if (currentDate === 0) return

        const timer = setInterval(() => {
            getLecons(agenceCalendrier, currentDate)
        }, 2000)

        return () => clearInterval(timer)
    }, [currentDate, agenceCalendrier])

    useEffect(() => {
        if (!agences) return
        setAgence(agences[agences.findIndex(a => a.id === agenceCalendrier)])
    }, [agences, agenceCalendrier])

    function createLecon(hDebTs, hFinTs, idMoniteur, isBA) {
        setShowFenetresEleves({ hDebTs, hFinTs, idMoniteur, isBA })
    }

    function CL(datas) {
        setLecon(datas, agenceCalendrier)
    }

    function _delLecon(hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA) {
        delLecon(hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, agenceCalendrier)
    }


    return (
        <div className='lecons'>
            {
                !lecons || !agences ? <Loader /> :
                    <div>
                        {showFenetresEleves &&
                            <FicheLecon
                                close={() => setShowFenetresEleves(false)}
                                //On affiche uniquement les élèves de la bonne agence avec le bon type de conduite (BM ou BA)
                                datas={showFenetresEleves}
                                createLecon={CL}
                                //eleves={eleves.filter(e => e.agence_id === agenceCalendrier && (showFenetresEleves.isBA ? e.permis_bm === 0 : e.permis_bm === 1)&& e.isInscrit === 1)} // Ici je modifie code pour affichertous les élèves, ceci peu importe le type de véhicule
                                eleves={eleves.filter(e => e.agence_id === agenceCalendrier && e.isInscrit === 1)}
                            />
                        }
                        {/* <button onClick={()=>getLecons(agenceCalendrier, currentDate)}>Refresh</button> */}

                        <h1>Sélectionner l'agence à afficher</h1>
                        <MultipleChoice
                            datas={agences}
                            current={agences.findIndex(e => e.id === agenceCalendrier)}
                            setNewValue={e => setAgenceCalendrier(e)}
                        />
                        <header>
                            <img src={ARROW} onClick={() => setCurrentDate(e => e - semaine)} />
                            <p>{agence.nom}<br /><span>{TimestampToDayMonthYear(lecons[0].timestamp)}</span> au <span>{TimestampToDayMonthYear(lecons[5].timestamp)}</span></p>
                            <img src={ARROW} onClick={() => setCurrentDate(e => e + semaine)} />
                        </header>
                        <div className='switchs'>
                            <Switch
                                label={"Afficher créneaux libres"}
                                initiale={showLibres}
                                devientOn={() => setShowLibres(true)}
                                devientOff={() => setShowLibres(false)}
                            />
                            <Switch
                                label={"Afficher créneaux réservés"}
                                initiale={showReserves}
                                devientOn={() => setShowReserves(true)}
                                devientOff={() => setShowReserves(false)}
                            />
                            <Switch
                                label={"Afficher congés"}
                                initiale={showConges}
                                devientOn={() => setShowConges(true)}
                                devientOff={() => setShowConges(false)}
                            />
                        </div>
                        <div className='tableau'>
                            {lecons.map((jour, i) =>
                                <article key={i}>
                                    <h3>{jour.day}<br />
                                        <span>{TimestampToDayMonth(jour.timestamp)}</span>
                                    </h3>
                                    {jour.error.length > 0 && <h4>Erreur:</h4>}
                                    {jour.error.length > 0 && jour.error.map((ampm, nb) =>
                                        ampm.hDeb !== -1 && ampm.hFin !== -1 &&
                                        <Cellule
                                            key={nb}
                                            ampm={ampm}
                                            delLecon={_delLecon}
                                            createLecon={createLecon}
                                            show={true}
                                        />
                                    )}{jour.annulee.length > 0 && <h4>Absences:</h4>}
                                    {jour.annulee.length > 0 && jour.annulee.map((ampm, nb) =>
                                        ampm.hDeb !== -1 && ampm.hFin !== -1 &&
                                        <Cellule
                                            key={nb}
                                            ampm={ampm}
                                            delLecon={_delLecon}
                                            createLecon={createLecon}
                                            show={true}
                                        />
                                    )}
                                    <h4>Matin:</h4>
                                    {jour.matin.map((ampm, nb) =>
                                        ampm.hDeb !== -1 && ampm.hFin !== -1 &&
                                        <Cellule
                                            key={nb}
                                            ampm={ampm}
                                            delLecon={_delLecon}
                                            createLecon={createLecon}
                                            show={
                                                !ampm.lecon && !ampm.indispo && !showLibres ? false :
                                                    !ampm.lecon && !ampm.indispo && showLibres ? true :
                                                        ampm.lecon && !showReserves ? false :
                                                            ampm.lecon && showReserves ? true :
                                                                !ampm.lecon && ampm.indispo && !showConges ? false : true
                                            }
                                        />
                                    )}
                                    <h4>Après-midi:</h4>
                                    {jour.aprem.map((ampm, nb) =>
                                        ampm.hDeb !== -1 && ampm.hFin !== -1 &&
                                        <Cellule
                                            key={nb}
                                            ampm={ampm}
                                            delLecon={_delLecon}
                                            createLecon={createLecon}
                                            show={
                                                !ampm.lecon && !ampm.indispo && !showLibres ? false :
                                                    !ampm.lecon && !ampm.indispo && showLibres ? true :
                                                        ampm.lecon && !showReserves ? false :
                                                            ampm.lecon && showReserves ? true :
                                                                !ampm.lecon && ampm.indispo && !showConges ? false : true
                                            }
                                        />
                                    )}
                                </article>
                            )
                            }
                        </div>
                    </div>
            }
        </div >
    )
}

const mapToProps = state => {
    return {
        lecons: state.lecons || false,
        agences: state.agences || false,
        agenceCalendrier: state.agenceCalendrier,
        eleves: state.eleves || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLecons: (agence_id, timestamp) => dispatch({ type: `GET_LECONS`, agence_id, timestamp }),
        setAgenceCalendrier: (agence_id) => dispatch({ type: `SET_AGENCE_CALENDRIER`, agence_id }),
        delLecon: (hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence) => dispatch({ type: `DEL_LECON`, hDebTs, hFinTs, idMoniteur, idEleve, avertir, BA, idAgence }),
        setLecon: (datas, agenceCalendrier) => dispatch({ type: `SET_LECON`, datas, agenceCalendrier }),
        getEleves: () => dispatch({ type: `GET_ELEVES` })
    }
}

export default connect(mapToProps, mapDispatchToProps)(Lecons)