import React, { useEffect, useState } from 'react'

import CROSS from './../pictos/cross.svg'

import './../pages/scss/Fiche.scss'


const FicheAnnulation = ({ oui, non, fermer }) => {

    return (
        <div className='flottant fiche-annulation'>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <div className='set-indispo'>
                <p>Vous allez libérer un créneau<br/>Voulez-vous envoyer un e-mail aux élèves susceptibles d'être intéressés par celui-ci?</p>
                <div className='buttons'>
                    <button onClick={() => oui()}>Oui</button>
                    <button className='saumon' onClick={() => non()}>Non</button>
                </div>
            </div>
        </div>
    )
}

export default FicheAnnulation