import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import HeuresMax from "../components/HeuresMax"

import './scss/Agences.scss'

const Agences = ({ agences }) => {

    const [hours, setHours] = useState(false)

    return (
        <div className="agences">
            {hours && <HeuresMax agenceId={hours.agence} close={() => setHours(false)} heuresInit={hours.heures} semainesInit={hours.semaines} />}
            <section className="liste-agences">
                {agences.map(agence =>
                    <article key={agence.id}>
                        <h2>{agence.nom}
                            <button
                                onClick={() => setHours({
                                    heures: agence.reservations_max_par_semaine,
                                    semaines: agence.sem_max_resa,
                                    agence: agence.id
                                })}
                            >Modifier</button>
                        </h2>
                        <p>
                            Nombre de réservation max d'un élève par semaine:
                            <em>{agence.reservations_max_par_semaine}</em><br />
                            Nombre de semaines durant lesquelles le calendrier est affiché:
                            <em>{agence.sem_max_resa ? agence.sem_max_resa : 'indéfini'}</em>
                        </p>
                    </article>
                )}
            </section>
        </div>
    )
}

const mapToProps = state => {
    return {
        agences: state.agences || []
    }
}


export default connect(mapToProps, null)(Agences)