const reducer = (state = { isLog: false, agenceCalendrier: 1 }, action) => {

    const newState = { ...state }

    if (action.type === "CONNEXION_ASYNC") {
        newState.isLog = action.isLog
        newState.me = action.me
    }

    if (action.type === "GET_GENERIQUES_ASYNC") {
        newState.agences = action.agences
    }

    if (action.type === "GET_ELEVES_ASYNC") {
        newState.eleves = action.eleves
    }

    if (action.type === "RAZ_ELEVE") {
        newState.eleve = false
        newState.calendrierEleve = []
    }

    if (action.type === "GET_ELEVE_ASYNC") {
        newState.eleve = action.eleve
    }

    if (action.type === "GET_MONITEURS_ASYNC") {
        newState.moniteurs = action.moniteurs
    }

    if (action.type === "GET_LECONS_ASYNC") {
        newState.lecons = action.lecons
    }

    if (action.type === "SET_AGENCE_CALENDRIER") {
        newState.agenceCalendrier = action.agence_id
    }

    if (action.type === "GET_INDISPOS_ASYNC") {
        newState.indispos = action.indispos
    }

    if(action.type === "GET_CALENDRIER_ELEVE_ASYNC"){
        newState.calendrierEleve = action.calendrier
    }

    if(action.type === 'GET_EXAMENS_ASYNC'){
        newState.examsEleve = action.examens
    }

    if(action.type === 'GET_ADMINISTRATEURS_ASYNC'){
        newState.administrateurs = action.administrateurs
    }

    return newState
}

export default reducer