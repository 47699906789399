import React, { useEffect } from "react"
import { Outlet, Link, useLocation } from "react-router-dom"
import { connect } from 'react-redux'


import MILKY from './images/MilkyW.png'

import './Structure.scss'

const Structures = ({ deconnexion, getGeneriques }) => {

    const location = useLocation()

    useEffect(() => {
        getGeneriques()
    }, [])

    return (
        <div>
            <header>
                <img src={MILKY} />
                <button className="saumon" onClick={deconnexion}>Deconnexion</button>
            </header>
            <div className="flex2">
                <section>
                    <nav>
                        <Link
                            className={`${location.pathname === "/" ? "select" : ""}`}
                            to="/">Leçons</Link>
                        <Link
                            className={`${location.pathname === "/eleves" ? "select" : ""}`}
                            to="/eleves">Elèves</Link>
                        <Link
                            className={`${location.pathname === "/moniteurs" ? "select" : ""}`}
                            to="/moniteurs">Moniteurs</Link>
                        <Link
                            className={`${location.pathname === "/agences" ? "select" : ""}`}
                            to="/agences">Agences</Link>
                        <Link
                            className={`${location.pathname === "/administrateurs" ? "select" : ""}`}
                            to="/administrateurs">Administrateurs</Link>
                    </nav>
                    <Outlet />
                </section>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getGeneriques: () => dispatch({ type: `GET_GENERIQUES` }),
    }
}


export default connect(null, mapDispatchToProps)(Structures)